<template>
  <div id="faq-page">
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
        <vx-card>
          <h4>{{ $t(resources.Type.i18n) || resources.Type.name }}</h4>
          <ul class="faq-topics mt-4">
            <li
              v-for="category in categories"
              :key="category.id"
              class="p-2 font-medium flex items-center"
              @click="faqFilter = category.id"
            >
              <div
                class="h-3 w-3 rounded-full mr-2"
                :class="'bg-' + category.color"
              ></div>
              <span class="cursor-pointer">{{ category.name }}</span>
            </li>
          </ul>
        </vx-card>
      </div>

      <!-- FAQ COL -->
      <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
        <vs-collapse accordion type="margin" class="p-0">
          <vs-collapse-item
            v-for="(x, index) in filteredFaq"
            class="faq-bg rounded-lg"
            :class="{ 'mt-0': !index }"
            :key="x.id"
          >
            <div slot="header">
              <h5>
                <strong>{{ x.title }}</strong>
              </h5>
            </div>
            <div v-html="x.body"></div>
            <vs-button
              v-if="x.attachementUrl !== null && x.attachementUrl !== ''"
              color="success"
              type="line"
              icon-pack="feather"
              icon="icon-download-cloud"
              size="large"
              @click="openDocument(`${x.attachementUrl}`)"
            ></vs-button>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      faqSearchQuery: "",
      dataList: [],
      faqFilter: "",
      categories: [],
      faqs: []
    };
  },
  async created() {
    await this.get();
    await this.getContenType();
  },
  computed: {
    filteredFaq() {
      return this.faqs.filter(faq => {
        if (this.faqFilter == "") return faq;
        else
          return (
            faq.contentTypeId == this.faqFilter &&
            (faq.title
              .toLowerCase()
              .includes(this.faqSearchQuery.toLowerCase()) ||
              faq.body
                .toLowerCase()
                .includes(this.faqSearchQuery.toLowerCase()))
          );
      });
    }
  },
  methods: {
    async get() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog/Search`,
        data: {
          searchKeys: {
            blogTypeId: 3
          },
          page: 1,
          rows: 200,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.faqs = [];
          } else {
            this.faqs = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getContenType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ContentType/GetByType/1/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.categories = [
              {
                id: "",
                name: this.$i18n.t("All"),
                color: "grey"
              }
            ];
          } else {
            this.categories = result.data.filter(x => x.id !== 0);

            this.categories.forEach(x => {
              if (x.id == 1 || x.id == 4) x["color"] = "success";
              if (x.id == 2 || x.id == 5) x["color"] = "primary";
              if (x.id == 3 || x.id == 6) x["color"] = "danger";
              if (x.id > 6) x["color"] = "warning";
            });

            //this.categories[0]['color'] = "warning";
            this.categories.push({
              id: "",
              name: this.$i18n.t("All"),
              color: "grey"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    openDocument(url) {
      window.open(url, this.$i18n.t("OpenDocument"));
    }
  },
  components: {}
};
</script>

<style lang="scss">
#faq-page {
  // .faq-jumbotron-content {
  //     background-image: url('../../assets/images/pages/faq.jpg');
  //     background-size: cover;
  // }

  .faq-bg {
    background-color: #fff;
  }
}
</style>
