var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "faq-page" } }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg" },
        [
          _c("vx-card", [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Type.i18n) || _vm.resources.Type.name
                )
              )
            ]),
            _c(
              "ul",
              { staticClass: "faq-topics mt-4" },
              _vm._l(_vm.categories, function(category) {
                return _c(
                  "li",
                  {
                    key: category.id,
                    staticClass: "p-2 font-medium flex items-center",
                    on: {
                      click: function($event) {
                        _vm.faqFilter = category.id
                      }
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "h-3 w-3 rounded-full mr-2",
                      class: "bg-" + category.color
                    }),
                    _c("span", { staticClass: "cursor-pointer" }, [
                      _vm._v(_vm._s(category.name))
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0" },
        [
          _c(
            "vs-collapse",
            { staticClass: "p-0", attrs: { accordion: "", type: "margin" } },
            _vm._l(_vm.filteredFaq, function(x, index) {
              return _c(
                "vs-collapse-item",
                {
                  key: x.id,
                  staticClass: "faq-bg rounded-lg",
                  class: { "mt-0": !index }
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("h5", [_c("strong", [_vm._v(_vm._s(x.title))])])
                  ]),
                  _c("div", { domProps: { innerHTML: _vm._s(x.body) } }),
                  x.attachementUrl !== null && x.attachementUrl !== ""
                    ? _c("vs-button", {
                        attrs: {
                          color: "success",
                          type: "line",
                          "icon-pack": "feather",
                          icon: "icon-download-cloud",
                          size: "large"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openDocument("" + x.attachementUrl)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }